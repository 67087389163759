import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Layout from '../components/layout';
import SEO from '../components/seo';
import RichText from '../components/richtext';

const ImpressPage = ({ data }) => {
  const impress = data.impress.edges.map(edge => edge.node);
  const { title, _rawContent } = impress[0];

  return (
    <>
      <Layout current="IMPRESS" disableUp disableDown>
        <SEO siteTitle="IMPRESS" />
        <StyledImpress className="light">
          <h2 className="big centered">{title}</h2>
          <div className="centered">
            <RichText blocks={_rawContent} />
          </div>
        </StyledImpress>
      </Layout>
    </>
  );
};

const StyledImpress = styled.section`
  padding: var(--spacing-S) var(--spacing-XL);
  margin-bottom: var(--spacing-L);

  h2 {
    padding-bottom: var(--spacing-XS);
  }

  p {
    padding-bottom: var(--spacing-XXS);
  }

  .centered {
    overflow: hidden;
  }

  @media (max-width: 1020px) {
    padding: var(--spacing-M) var(--spacing-XXS);
  }
`;

export const query = graphql`
  query Impress {
    impress: allSanityLegalNotice {
      edges {
        node {
          title
          _rawContent
        }
      }
    }
  }
`;

ImpressPage.propTypes = {
  data: PropTypes.object,
};

export default ImpressPage;
